exports.components = {
  "component---src-components-blogs-blog-detail-js": () => import("./../../../src/components/blogs/blogDetail.js" /* webpackChunkName: "component---src-components-blogs-blog-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-cloud-computing-js": () => import("./../../../src/pages/services/cloud-computing.js" /* webpackChunkName: "component---src-pages-services-cloud-computing-js" */),
  "component---src-pages-services-graphics-design-js": () => import("./../../../src/pages/services/graphics-design.js" /* webpackChunkName: "component---src-pages-services-graphics-design-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-development-js": () => import("./../../../src/pages/services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-js" */),
  "component---src-pages-services-quality-assurance-js": () => import("./../../../src/pages/services/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-quality-assurance-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-services-ui-ux-js": () => import("./../../../src/pages/services/ui-ux.js" /* webpackChunkName: "component---src-pages-services-ui-ux-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-somi-js": () => import("./../../../src/pages/somi.js" /* webpackChunkName: "component---src-pages-somi-js" */),
  "component---src-pages-strapi-post-slug-js": () => import("./../../../src/pages/{strapiPost.slug}.js" /* webpackChunkName: "component---src-pages-strapi-post-slug-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

